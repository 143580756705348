import React from 'react'
import styled from 'styled-components'

import { graphql } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Img from 'gatsby-image'
import { Flex, Box, Button, Text, Heading } from 'rebass/styled-components'
import { useShopContext } from 'context/provider'
import { addToCart } from 'context/actions'
import priceHelper from 'utils/priceHelper'

export const pageQuery = graphql`
  query ProductDetailBySku($sku: String!) {
    contentfulProduct(sku: { eq: $sku }) {
      name
      currency
      amount
      sku
      image {
        fluid(quality: 90, maxWidth: 600, maxHeight: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

const ProductDetailPage = ({ data }) => {
  const { dispatch } = useShopContext()
  const product = data.contentfulProduct

  return (
    <Layout>
      <SEO title="Product Detail" />
      <Heading textAlign="center" p={3}>
        {product.name}
      </Heading>
      <Flex mt={2} flexDirection={['column', 'column', 'row']}>
        <Flex width="600px" justifyContent="center">
          <Image fluid={product.image.fluid} />
        </Flex>
        <Flex flexDirection="column" flex={1} pt={3} pl={3} alignItems="center">
          <Text fontSize={[2, 3, 4]}>{priceHelper(product.amount)}</Text>
          <Box>
            <Button
              as="a"
              href="#"
              px={3}
              py={2}
              onClick={() => dispatch(addToCart(product))}>
              Add to cart
            </Button>
          </Box>
          <Box
            bg="darkgray"
            my={4}
            width={0.9}
            height="1px"
            opacity={0.5}></Box>
          <Markdown
            dangerouslySetInnerHTML={{
              __html: product.description.childMarkdownRemark.html,
            }}
          />
        </Flex>
      </Flex>
    </Layout>
  )
}

const Image = styled(Img)`
  width: 100%;
`

const Markdown = styled.div`
  h1,
  h2,
  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
`

export default ProductDetailPage
